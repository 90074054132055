import { ProgressIndicator, Toggle } from '@veneer/core';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { useShellRootContext } from 'src/contexts/ShellRoot';
import { BREAKPOINT_TABLET } from '../../breakpoints';
import { StateContext } from '../../store';
import IconCheckMark from '../icons/IconCheckMark';
import IconPeople from '../icons/IconPeople';
import IconPerson from '../icons/IconPerson';

import { parseAndBuildString } from 'src/utils/string';
import styles from './Printer.module.scss';

export const PREFERED_RELEASE_INTENT = {
  DEFAULT_AUTO: 'DEFAULT_AUTO',
  DEFAULT_HOLD: 'DEFAULT_HOLD',
  AUTO_ONLY: 'AUTO_ONLY',
  HOLD_ONLY: 'HOLD_ONLY'
};
const PRINT_RELATION_SHARED = 'shared';

const Printer = ({
  printer,
  printOnTheGoEnabled,
  printerRelation,
  printOnTheGoToggleLoading,
  handlePrintOnTheGoChange,
  preferredReleaseIntent,
  preferredReleaseIntentLoading,
  handlePreferredReleaseIntentChange,
  nonOwner,
  printerSharing,
  clientReleaseIntent,
  clientReleaseIntentLoading
}) => {
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {};
  const {
    state: { breakpoints }
  } = useContext(StateContext);

  const renderCheckMark = (text) => {
    return (
      <div
        data-testid="potg-printer-checkmark-text"
        className={styles.icon_checkmark_text}
      >
        {text}
        <span className={styles.icon_checkmark}>
          <IconCheckMark />
        </span>
      </div>
    );
  };

  const getNonAdminValue = () =>
    printerSharing
      ? printerRelation.toLowerCase() === PRINT_RELATION_SHARED
      : nonOwner;

  const renderPrintAnywhere = () => {
    //if (nonOwner)
    if (getNonAdminValue()) {
      return printOnTheGoEnabled ? (
        renderCheckMark(t('printers.printAnywhere.nonOwnerUser.on', ''))
      ) : (
        <span
          data-testid="potg-printer-private-pickup-text"
          className={styles.private_pickup_text}
        >
          {t('printers.printAnywhere.nonOwnerUser.off', '')}
        </span>
      );
    } else {
      return (
        <Toggle
          id={`toggle_printOnTheGo_printer_${printer.id}`}
          data-testid={`toggle_printOnTheGo_printer_${printer.id}`}
          className={styles.printer__toggle}
          disabled={printOnTheGoToggleLoading}
          on={printOnTheGoEnabled}
          onChange={(isOn) => handlePrintOnTheGoChange(printer.id, isOn)}
        />
      );
    }
  };

  const renderPrivatePickup = () => {
    if (
      (getNonAdminValue() || !printer.supportsPull) &&
      printOnTheGoEnabled &&
      printer.supportsPull
    ) {
      //NONOWNER && (PRINTONTHEGOENABLED && SUPPORTSPULL)
      if (preferredReleaseIntent === PREFERED_RELEASE_INTENT.HOLD_ONLY) {
        return renderCheckMark(
          t('printers.privatePickup.nonOwnerUser.required', '')
        );
      } else {
        return (
          <span
            data-testid="potg-printer-private-pickup-non-owner-text"
            className={styles.private_pickup_text}
          >
            {t('printers.privatePickup.nonOwnerUser.disabled', '')}
          </span>
        );
      }
    } else if (getNonAdminValue() || !printer.supportsPull) {
      return (
        //  (!PRINTONTHEGOENABLED || !SUPPORTSPULL)
        //   the only time we have the owner==true here is when supportsPull is false
        <span
          data-testid="potg-printer-private-pickup-non-owner-text"
          className={styles.private_pickup_text}
        >
          {t('printers.privatePickup.nonOwnerUser.notAvailable', '')}
        </span>
      );
    } else {
      //OWNER && SUPPORTSPULL
      return (
        <Toggle
          id={`toggle_privatePickUp_printer_${printer.id}`}
          data-testid={`toggle_privatePickUp_printer_${printer.id}`}
          className={styles.printer__toggle}
          disabled={!printOnTheGoEnabled || preferredReleaseIntentLoading}
          on={preferredReleaseIntent === PREFERED_RELEASE_INTENT.HOLD_ONLY}
          onChange={(isOn) => {
            if (isOn) {
              handlePreferredReleaseIntentChange(
                printer.id,
                PREFERED_RELEASE_INTENT.HOLD_ONLY
              );
            } else if (clientReleaseIntent) {
              handlePreferredReleaseIntentChange(
                printer.id,
                PREFERED_RELEASE_INTENT.DEFAULT_AUTO
              );
            } else {
              handlePreferredReleaseIntentChange(
                printer.id,
                PREFERED_RELEASE_INTENT.AUTO_ONLY
              );
            }
          }}
        />
      );
    }
  };

  const inMobileResolution = !breakpoints[BREAKPOINT_TABLET];

  return (
    <div
      data-testid="potg-printers-printer"
      className={styles.printer}
    >
      <div
        data-testid="potg-printers-printer-info"
        className={styles.printer__info}
      >
        <figure className={styles.printer__image}>
          <img
            alt={printer.name}
            src={printer.icons[3].url}
          />
        </figure>
        <div className={styles.printer__name_wrapper}>
          <h4
            data-testid="potg-printers-printer-name"
            className={styles.printer__name}
          >
            {printer.modelName}
          </h4>
          {printerSharing && (
            <div className={styles.printer__sharing_info}>
              {printerRelation.toLowerCase() === 'shared' && (
                <span>
                  <span>{<img src={IconPeople}></img>}</span>
                  <span className={styles.print_anywhere_printer_sharing_title}>
                    {t('printers.user', '')}
                  </span>
                </span>
              )}
              {printerRelation.toLowerCase() === 'admin' && (
                <span>
                  <span>{<img src={IconPerson}></img>}</span>
                  <span className={styles.print_anywhere_printer_sharing_title}>
                    {t('printers.admin', '')}
                  </span>
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={styles.printer__options}>
        <div
          data-testid="potg-printers-print-anywhere-wrapper"
          className={styles.printer__print_anywhere}
        >
          {inMobileResolution && (
            <h5 className={styles.printer__print_anywhere__title}>
              {t('printers.printAnywhere.columnName', '')}
            </h5>
          )}
          {printOnTheGoToggleLoading ? (
            <ProgressIndicator
              className={styles.printer__info__spinner}
              size={36}
            />
          ) : (
            renderPrintAnywhere()
          )}
        </div>
        <div
          data-testid="potg-printers-private-pickup-wrapper"
          className={styles.printer__private_pickup}
        >
          {inMobileResolution && (
            <div className={styles.printer__private_pickup__text}>
              <h5 className={styles.printer__private_pickup__title}>
                {parseAndBuildString(
                  t('printers.privatePickup.columnName', '')
                )}
              </h5>
              <span className={styles.printer__private_pickup__description}>
                {t('printers.privatePickup.columnDescription', '')}
              </span>
            </div>
          )}
          {preferredReleaseIntentLoading || clientReleaseIntentLoading ? (
            <ProgressIndicator
              className={styles.printer__info__spinner}
              size={36}
            />
          ) : (
            renderPrivatePickup()
          )}
        </div>
      </div>
    </div>
  );
};

Printer.propTypes = {
  printer: PropTypes.objectOf(PropTypes.any).isRequired,
  printOnTheGoEnabled: PropTypes.bool.isRequired,
  printOnTheGoToggleLoading: PropTypes.bool.isRequired,
  handlePrintOnTheGoChange: PropTypes.func.isRequired,
  preferredReleaseIntent: PropTypes.string.isRequired,
  preferredReleaseIntentLoading: PropTypes.bool.isRequired,
  handlePreferredReleaseIntentChange: PropTypes.func.isRequired
};

export default React.memo(Printer);
