import { useApiCall } from '@jarvis/react-portal-addons';
import { JarvisWebHttpClient } from '@jarvis/web-http';
import { useCallback } from 'react';
import { getPrintAnywhereUrl } from 'src/services/api';

export default function useUserInfoApiCall() {
  const apiCall = useCallback(async () => {
    const client = new JarvisWebHttpClient({
      defaultBaseURL: getPrintAnywhereUrl(window.Shell.v1.app.getAuthStack()),
      defaultAuthProvider: window.Shell.v1.authProvider
    });
    const options = {
      url: `/api/v1/user/self`
    };
    try {
      const response = await client.get(options);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  return useApiCall({ apiCall });
}
