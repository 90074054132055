import React from 'react';

const IconCheckMark = () => (
  <svg
    data-testid="potg-icon-check-mark"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      style={{ fill: '#1c7a17' }}
      d="M12,3a9,9,0,1,1-9,9,9.01,9.01,0,0,1,9-9m0-1A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM10.4,15.528l6.448-6.343a.5.5,0,1,0-.7-.713l-6.1,6-2.7-2.658a.5.5,0,0,0-.7.713l3.051,3a.5.5,0,0,0,.7,0Z"
    />
    <rect
      style={{ fill: 'none' }}
      width="24"
      height="24"
    />
  </svg>
);

export default IconCheckMark;
