import { ProgressIndicator } from '@veneer/core';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import usePrintersApiCall from 'src/hooks/usePrintersApiCall';
import { StateContext } from '../../store';
import {
  actionFetchPrinters,
  actionUserUpdateNonOwner,
  actionFetchPrintersSuccess
} from '../../store/action-creators';
import JobHistoryPanel from '../JobHistoryPanel/JobHistoryPanel';
import JobQueuePanel from '../JobQueuePanel/JobQueuePanel';
import PrintAnywhereTitle from '../PrintAnywhereTitle';
import PrintersPanel from '../PrintersPanel/PrintersPanel';
import SomethingWentWrongError from '../error/SomethingWentWrongError';
import styles from './styles';
import useRolesApiCall from 'src/hooks/useRolesApiCall';
import useUserInfoApiCall from 'src/hooks/useUserInfoApiCall';

const COMPONENT_JOBQUEUE = 'jobqueue';
const COMPONENT_PRINTERS = 'printers';
const COMPONENT_JOBHISTORY = 'jobhistory';
const COMPONENT_ERROR = 'error';
const COMPONENT_LOADING = 'loading';
const ADMIN = 'ADMIN';
const ROLE_NAME = 'roleName';

const Potg = () => {
  const {
    state: {
      printers: { loading: loadingPrinters }
    },
    dispatch
  }: /* eslint-disable @typescript-eslint/no-explicit-any */
  any = useContext(StateContext);

  const { component } = useParams();

  const stratusValues = (
    window as any
  ).Shell.v1.authProvider.getDecodedUserStratusJWTPayload();
  //map of printers cloud id to printer model names to pass as prop to JobHistoryPanel
  const [printersNamesMap, setPrintersNamesMap] = useState({});
  const [stratusTenantId, setStratusTenantId] = useState(
    stratusValues?.stratus_id
  );
  const [stratusResourceId, setStratusResourceId] = useState(
    stratusValues?.tenant_id
  );

  const [activeComponent, setActiveComponent] = useState('');

  let toBeRendered = (
    <>
      <PrintAnywhereTitle />
      <PrintersPanel />
      <JobQueuePanel />
      <JobHistoryPanel
        printersNamesMap={printersNamesMap}
        stratusResourceId={stratusResourceId}
        stratusTenantId={stratusTenantId}
      />
    </>
  );

  const {
    loading: printersApiLoading,
    data: printersApiData,
    error: printersAPiError
  } = usePrintersApiCall();
  useEffect(() => {
    if (!printersApiLoading && printersApiData) {
      const printers = printersApiData?.content;
      if (printers && printers.length > 0) {
        let printerSharing = false;
        const printersNameMap = {};
        printers.forEach((printer) => {
          printersNameMap[printer.cloudId] = printer.modelName;
          if (printer.printerRelation) {
            printerSharing = true;
          }
        });
        setPrintersNamesMap(printersNameMap);

        dispatch(actionFetchPrintersSuccess(printers, printerSharing));
      } else if (
        printers &&
        printers.length === 0 &&
        component !== COMPONENT_PRINTERS &&
        component !== COMPONENT_JOBQUEUE &&
        component !== COMPONENT_JOBHISTORY
      ) {
        setActiveComponent(COMPONENT_PRINTERS);
      } else {
        setActiveComponent(component);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printersApiLoading, printersApiData, printersAPiError]);

  useEffect(() => {
    if (loadingPrinters) {
      setStratusResourceId(stratusValues?.stratus_id);
      setStratusTenantId(stratusValues?.tenant_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPrinters]);
  const userInfo = useUserInfoApiCall();
  const {
    loading: rolesApiLoading,
    data: rolesApiData,
    error: rolesApiError
  } = useRolesApiCall(userInfo?.data?.id);
  useEffect(() => {
    if (!rolesApiLoading && rolesApiData) {
      const roleNames = rolesApiData?.content.map((obj) => obj[ROLE_NAME]);
      if (roleNames) {
        dispatch(
          actionUserUpdateNonOwner(roleNames.includes(ADMIN) ? false : true)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesApiLoading, rolesApiData, rolesApiError]);

  useEffect(() => dispatch(actionFetchPrinters()), [dispatch]);

  if (activeComponent?.toLocaleLowerCase() === COMPONENT_LOADING) {
    toBeRendered = <ProgressIndicator />;
  } else if (activeComponent?.toLocaleLowerCase() === COMPONENT_ERROR) {
    toBeRendered = (
      <SomethingWentWrongError
        onConfirm={() => {
          setActiveComponent(COMPONENT_LOADING);
          dispatch(actionFetchPrinters());
        }}
      />
    );
  }

  return (
    <div
      data-testid="potg-app"
      style={styles.potg_app}
    >
      {toBeRendered}
    </div>
  );
};

export default Potg;
