export const ACTION_MODAL_OPEN = 'ACTION_MODAL_OPEN';
export const ACTION_MODAL_CLOSE = 'ACTION_MODAL_CLOSE';

export const ACTION_FETCH_PRINTERS = 'ACTION_FETCH_PRINTERS';
export const ACTION_FETCH_PRINTERS_SUCCESS = 'ACTION_FETCH_PRINTERS_SUCCESS';
export const ACTION_FETCH_PRINTERS_FAILURE = 'ACTION_FETCH_PRINTERS_FAILURE';

export const ACTION_UPDATE_PRINTER_DATA = 'ACTION_UPDATE_PRINTER_DATA';
export const ACTION_UPDATE_PRINTER_FEATURE_LOADING =
  'ACTION_UPDATE_PRINTER_FEATURE_LOADING';

export const ACTION_USER_UPDATE_NON_OWNER = 'ACTION_USER_UPDATE_NON_OWNER';
export const ACTION_UPDATE_PRINTER_SHARING = 'ACTION_UPDATE_PRINTER_SHARING';

export const ACTION_FETCH_JOB_HISTORY_API = 'ACTION_FETCH_JOB_HISTORY_API';
