import { Card, Pagination, ProgressIndicator, useToast } from '@veneer/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { deleteJob } from '../../services/api';
import IconPositiveArrow from '../icons/IconPositiveArrow';
import JobsTable from './JobsTable';

import useJobsApiCall from 'src/hooks/useJobsApiCall';
import { StateContext } from 'src/store';
import { actionFetchJobHistoryApi } from 'src/store/action-creators';
import styles from './JobQueuePanel.module.scss';

const JobQueuePanel = () => {
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {};

  const { dispatch } = useContext(StateContext);
  const [pagination, setPagination] = React.useState({
    pageNumber: 1,
    pageSize: 10
  });
  const [totalJobs, setTotalJobs] = useState(0);
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const { addToast } = useToast();
  const {
    loading: jobsApiLoading,
    data: jobsApiData,
    error: jobsApiError,
    makeApiCall: refreshJobsApiCall
  } = useJobsApiCall({ pageNumber: pagination.pageNumber }) || {
    loading: true,
    data: {}
  };
  useEffect(() => {
    if (!jobsApiLoading && jobsApiData) {
      setJobs(jobsApiData?.content);
      setTotalJobs(jobsApiData?.page?.totalElements);
    } else if (jobsApiError) {
      addToast({
        type: 'negative',
        id: 'printAnywhere-privatePickupFiles-fetchJobs-error-toast',
        text: t('privatePickupFiles.title', ''),
        subtitle: t('privatePickupFiles.fetchJobsError', '')
      });
    }
    setLoading(jobsApiLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobsApiLoading, jobsApiData, jobsApiError]);

  const handleDeleteJob = useCallback(
    (jobId) => {
      setLoading(true);
      deleteJob(jobId)
        .then(() => {
          refreshJobsApiCall();
          dispatch(actionFetchJobHistoryApi(true));
          setPagination((pagination) => ({ ...pagination, pageNumber: 1 }));
        })
        .catch(() =>
          addToast({
            type: 'negative',
            id: 'printAnywhere-privatePickupFiles-deleteJob-error-toast',
            text: t('privatePickupFiles.title', ''),
            subtitle: t('privatePickupFiles.delete.error', '')
          })
        )
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setLoading, addToast]
  );

  return (
    <div
      data-testid="potg-jobqueue-panel"
      className={styles.jobqueue_panel}
    >
      <Card
        className={styles.jobqueue_panel__card}
        appearance="dropShadow"
        style={{ borderWidth: 0 }}
        content={
          <>
            <div className={styles.jobqueue_panel__header}>
              <div>
                <h5 className={styles.jobqueue_panel__title}>
                  {t('privatePickupFiles.title', '')}
                  {totalJobs > 0 ? `\u00a0 (${totalJobs})` : null}
                </h5>
                {/* Left this commented out code on purpose for now*/}
                {/* <span className={styles.jobqueue_panel__description}> */}
                {t('privatePickupFiles.subtitle', '')}
                {/* </span> */}
              </div>
            </div>
            {!loading && jobs.length === 0 && (
              <div className={styles.jobqueue_empty}>
                <IconPositiveArrow />
                <div className={styles.jobqueue_empty__title}>
                  {t('privatePickupFiles.readyToReceiveFiles', '')}
                </div>
              </div>
            )}
            {loading && (
              <div className={styles.jobqueue__loading_wrapper}>
                <ProgressIndicator
                  className={styles.jobqueue__loading}
                  appearance="circular"
                  size={48}
                />
              </div>
            )}
            {!loading && jobs.length > 0 && (
              <JobsTable
                jobs={jobs}
                handleDeleteJob={handleDeleteJob}
                isHistoryTable={false}
              />
            )}
          </>
        }
      />
      {jobs.length > 0 && totalJobs > pagination.pageSize ? (
        <div className={styles.jobqueue__pagination_container}>
          <Pagination
            className={styles.jobqueue__pagination}
            currentPage={pagination.pageNumber}
            pageSize={pagination.pageSize}
            onPageChange={(page) =>
              setPagination((pagination) => ({
                ...pagination,
                pageNumber: page
              }))
            }
            totalItems={totalJobs}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default React.memo(JobQueuePanel);
