// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ikVyMHrh9IbojmGCQuVf{display:flex;flex-direction:column;justify-content:center;align-items:center;margin-top:20px}.pkJJ4H2N8MDLlWeVehHw{text-align:center;font-size:18px;width:390px;height:69px;margin-bottom:40px}.DNFOOPYlJ7LXaHp9GF38{vertical-align:middle;margin-top:20px}", "",{"version":3,"sources":["webpack://./src/components/error/SomethingWentWrongError.module.scss"],"names":[],"mappings":"AAAA,sBAAA,YAAA,CAAA,qBAAA,CAAA,sBAAA,CAAA,kBAAA,CAAA,eAAA,CAAA,sBAAA,iBAAA,CAAA,cAAA,CAAA,WAAA,CAAA,WAAA,CAAA,kBAAA,CAAA,sBAAA,qBAAA,CAAA,eAAA","sourcesContent":[".something_went_wrong_error{display:flex;flex-direction:column;justify-content:center;align-items:center;margin-top:20px}.something_went_wrong_error__description{text-align:center;font-size:18px;width:390px;height:69px;margin-bottom:40px}.something_went_wrong_error__button{vertical-align:middle;margin-top:20px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"something_went_wrong_error": "ikVyMHrh9IbojmGCQuVf",
	"something_went_wrong_error__description": "pkJJ4H2N8MDLlWeVehHw",
	"something_went_wrong_error__button": "DNFOOPYlJ7LXaHp9GF38"
};
export default ___CSS_LOADER_EXPORT___;
