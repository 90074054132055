import { useLocation } from 'react-router-dom';
import 'url-search-params-polyfill';

function useQueryParams() {
  const query = new URLSearchParams(useLocation().search);
  return Array.from(query.keys()).reduce(
    (acc, param) => ({ ...acc, [param]: query.get(param) }),
    {}
  );
}

export default useQueryParams;
