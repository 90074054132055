import React from 'react';

const IconColor = () => (
  <svg
    data-testid="potg-icon-color"
    xmlns="http://www.w3.org/2000/svg"
    width="19.263"
    height="18.419"
    viewBox="0 0 19.263 18.419"
  >
    <circle
      fill="#f8448c"
      cx="4.386"
      cy="4.386"
      r="4.386"
      transform="translate(10.491 9.354)"
    />
    <circle
      fill="#ffd907"
      cx="4.386"
      cy="4.386"
      r="4.386"
      transform="translate(5.103)"
    />
    <circle
      fill="#34a0d9"
      cx="4.373"
      cy="4.373"
      r="4.373"
      transform="translate(0 9.672)"
    />
  </svg>
);

export default IconColor;
