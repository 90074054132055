import React, { useCallback, useReducer, useMemo } from 'react';
import { combineReducers } from './util';
import reducers from './reducers';
import { useBreakpoint } from '../hooks';
import breakpoints from '../breakpoints';
import { ToastProvider } from '@veneer/core';

export const StateContext = React.createContext({});

export const StateProvider = ({ children }) => {
  const getCombinedReducers = useCallback(() => combineReducers(reducers), []);

  const [initialState, combinedReducers] = getCombinedReducers();

  const [state, dispatch] = useReducer(combinedReducers, initialState);
  const activeBreakpoints = useBreakpoint(breakpoints);
  const contextValue = useMemo(() => {
    return { state: { ...state, breakpoints: activeBreakpoints }, dispatch };
  }, [state, activeBreakpoints, dispatch]);
  /* At this point the above contextValue has a structure like this:
       {
           state: {
               modal: {visible: false, component: null, props: null},
               printers:{
                   loading: false,
                   printerSharing: false, 
                   printers: [
                       {}, {}, ...
                   ] ,
                   error: null,
                   featuresLoading: {
                       <printerID>: {printOnTheGoEnabled: false, preferredReleaseIntent: false},
                       <printerID>: {printOnTheGoEnabled: false, preferredReleaseIntent: false}
                   }

               },
               user:{nonOwner: false},
               breakpoints: {lg: true, md: true, ml: true, mm: true, ms: true, sm: true, xl: false}
           },
           dispatch: dispatch
       }
    */
  return (
    <StateContext.Provider value={contextValue}>
      <ToastProvider position="top">{children}</ToastProvider>
    </StateContext.Provider>
  );
};
