import { useToast } from '@veneer/core';
import classnames from 'classnames';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useShellRootContext } from 'src/contexts/ShellRoot';

import useClientReleaseIntentApiCall from 'src/hooks/useClientReleaseIntentApiCall';
import { BREAKPOINT_TABLET } from '../../breakpoints';
import { updatePrinter } from '../../services/api';
import { StateContext } from '../../store';
import {
  actionUpdatePrinterData,
  actionUpdatePrinterFeatureLoading
} from '../../store/action-creators';
import Printer from './Printer';

import { parseAndBuildString } from '../../utils/string';
import styles from './PrinterList.module.scss';

const PrinterList = () => {
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {};
  const {
    state: {
      printers: { printers, printerSharing, featuresLoading },
      user: { nonOwner },
      breakpoints
    },
    dispatch
  } = useContext(StateContext);

  const { addToast } = useToast();
  const [clientReleaseIntent, setClientReleaseIntent] = useState(false);
  const [clientReleaseIntentLoading, setClientReleaseIntentLoading] =
    useState(true);
  const {
    data: clientRelIntentData,
    loading: clientRelIntentLoading,
    error: clientRelIntentError
  } = useClientReleaseIntentApiCall();

  useEffect(() => {
    if (!clientRelIntentLoading && clientRelIntentData) {
      setClientReleaseIntent(clientRelIntentData?.clientReleaseIntent);
    }
    setClientReleaseIntentLoading(clientRelIntentLoading);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientRelIntentLoading, clientRelIntentData, clientRelIntentError]);

  const handlePrintOnTheGoChange = useCallback(
    (printerId, printOnTheGoEnabled) => {
      dispatch(
        actionUpdatePrinterFeatureLoading({
          printerId: printerId,
          featureName: 'printOnTheGoEnabled',
          loading: true
        })
      );

      updatePrinter(printerId, { printOnTheGoEnabled })
        .then(() =>
          dispatch(
            actionUpdatePrinterData({
              printerId: printerId,
              data: {
                printOnTheGoEnabled
              }
            })
          )
        )
        .catch(() => {
          addToast({
            type: 'negative',
            id: 'printAnywhere-printAnywhere-toggle-error-toast',
            text: parseAndBuildString(
              t('printers.printAnywhere.columnName', '')
            ),
            subtitle: t('printers.printAnywhere.toggleError', '')
          });
        })
        .finally(() => {
          dispatch(
            actionUpdatePrinterFeatureLoading({
              printerId: printerId,
              featureName: 'printOnTheGoEnabled',
              loading: false
            })
          );
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, addToast]
  );

  const handlePreferredReleaseIntentChange = useCallback(
    (printerId, preferredReleaseIntent) => {
      dispatch(
        actionUpdatePrinterFeatureLoading({
          printerId: printerId,
          featureName: 'preferredReleaseIntent',
          loading: true
        })
      );
      updatePrinter(printerId, { preferredReleaseIntent })
        .then(() =>
          dispatch(
            actionUpdatePrinterData({
              printerId: printerId,
              data: {
                preferredReleaseIntent
              }
            })
          )
        )
        .catch(() => {
          addToast({
            type: 'negative',
            id: 'printAnywhere-privatePickup-toggle-error-toast',
            text: parseAndBuildString(
              t('printers.privatePickup.columnName', '')
            ),
            subtitle: t('printers.privatePickup.toggleError', '')
          });
        })
        .finally(() => {
          dispatch(
            actionUpdatePrinterFeatureLoading({
              printerId: printerId,
              featureName: 'preferredReleaseIntent',
              loading: false
            })
          );
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, addToast]
  );

  const inMobileResolution = !breakpoints[BREAKPOINT_TABLET];

  return printers.length > 0 ? (
    <div data-testid="potg-printers-list">
      <div className={styles.printer_list__header}>
        <h4 className={styles.printer_list__title}>
          {t('printers.title', '')}
        </h4>
        {!inMobileResolution && (
          <>
            <div className={styles.columns__print_anywhere}>
              <h5 className={styles.columns__print_anywhere__title}>
                {t('printers.printAnywhere.columnName', '')}
              </h5>
            </div>
            <div className={styles.columns__private_pickup}>
              <h5 className={styles.columns__private_pickup__title}>
                {parseAndBuildString(
                  t('printers.privatePickup.columnName', '')
                )}
              </h5>
              <span className={styles.columns__private_pickup__description}>
                {t('printers.privatePickup.columnDescription', '')}
              </span>
            </div>
          </>
        )}
      </div>
      {printers.map((printer) => {
        return (
          <Printer
            key={printer.id}
            printerRelation={printer.printerRelation}
            nonOwner={nonOwner}
            printerSharing={printerSharing}
            printer={printer}
            printOnTheGoEnabled={printer.printOnTheGoEnabled}
            printOnTheGoToggleLoading={
              featuresLoading[printer.id].printOnTheGoEnabled
            }
            handlePrintOnTheGoChange={handlePrintOnTheGoChange}
            preferredReleaseIntent={printer.preferredReleaseIntent}
            preferredReleaseIntentLoading={
              featuresLoading[printer.id].preferredReleaseIntent
            }
            handlePreferredReleaseIntentChange={
              handlePreferredReleaseIntentChange
            }
            clientReleaseIntent={clientReleaseIntent}
            clientReleaseIntentLoading={clientReleaseIntentLoading}
          />
        );
      })}
    </div>
  ) : (
    <div
      data-testid="potg-printers-panel-no-printers"
      className={classnames(styles.printers_panel__no_printers)}
    >
      <h4 className={classnames(styles.printers_panel__no_printers__title)}>
        {t('printers.title', '')}
      </h4>
      <h4
        className={classnames(styles.printers_panel__no_printers__description)}
      >
        {t('printers.empty', '')}
      </h4>
    </div>
  );
};

export default React.memo(PrinterList);
