import { RootProvider } from '@jarvis/react-portal-addons';
import { DirectionProvider } from '@veneer/core';
import { ThemeProvider } from '@veneer/theme';
import React, { useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { App } from 'src';
import resources from 'src/assets/locale';
import PotgModal from 'src/components/modals/PotgModal';
import { ShellRootProvider } from 'src/contexts/ShellRoot';
import { StateProvider } from 'src/store';
import {
  InterfacesType,
  ShellRootComponentProps,
  TranslatorFunctionType
} from 'src/types/shell';
import projectNames from './configs/projectNames';

export default function Root({
  useToast,
  properties,
  ...props
}: ShellRootComponentProps) {
  const [t, setT] = useState<TranslatorFunctionType>();
  // You can find all Shell´s props here. See "src/types/shell" for more details.
  const { v1, v2 } = window.Shell as InterfacesType;
  const interfaces = {
    v1,
    v2
  };

  const directionValue =
    v1?.localization?.useReactGetLanguageDirection?.(React);

  const { themeProviderProps, userThemeMode } = useMemo(() => {
    return {
      themeProviderProps: v1?.theme?.getThemeProviderProperties?.(),
      userThemeMode: v1?.theme?.getUserThemeMode?.()
    };
  }, [v1?.theme]);

  // Create a translator function by providing the resource files
  useEffect(() => {
    v1.localization
      .createTranslatorFunction(resources)
      .then((v) => setT(() => v));
  }, [v1]);

  // Wait for the translator function creation to show the content
  if (!t) {
    return null;
  }

  const { stack, analytics } = props;

  const printElement = (
    <DirectionProvider direction={directionValue}>
      <RootProvider
        localization={v1.localization}
        resources={resources}
        shell={v1}
        authProvider={v1.authProvider}
        stack={stack}
        analytics={analytics}
      >
        <ThemeProvider
          {...themeProviderProps}
          mode={userThemeMode}
        >
          <ShellRootProvider {...{ interfaces, t, useToast, properties }}>
            <StateProvider>
              <PotgModal />
              <App />
            </StateProvider>
          </ShellRootProvider>
        </ThemeProvider>
      </RootProvider>
    </DirectionProvider>
  );

  const router = createBrowserRouter([
    {
      path: '/:component?',
      element: printElement,
      errorElement: printElement,
      loader: () => {
        throw new Error('error');
      }
    }
  ]);

  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <ErrorBoundary fallback={<p>Something went wrong!</p>}>
        <RouterProvider router={router} />
      </ErrorBoundary>
    </section>
  );
}
