import { JarvisWebHttpClient } from '@jarvis/web-http';
import { Stack } from '@jarvis/web-stratus-client';

export const getPrintAnywhereUrl = (stack) => {
  const urlByStack = {
    [Stack.dev]: 'https://api-rsantos2.hpalpine.com',
    [Stack.pie]: 'https://api-rsantos2.goblin.hpalpine.com',
    [Stack.stage]: 'https://api-staging.ogre.hpalpine.com',
    [Stack.prod]: 'https://roam.api.hp.com'
  };
  return urlByStack[stack] || urlByStack[Stack.prod];
};

const client = new JarvisWebHttpClient({
  defaultBaseURL: getPrintAnywhereUrl(window.Shell?.v1?.app?.getAuthStack()),
  defaultAuthProvider: window.Shell?.v1?.authProvider
});

export const updatePrinter = (printerId, data) =>
  client.patch({ url: `/api/v1/printer/${printerId}`, data });

export const deleteJob = (jobId) =>
  client.delete({ url: `/api/v2/job/${jobId}` });

export default client;
