const PrintAnyWhereTitleStyles = {
  print_anywhere_intro: {
    maxWidth: '960px',
    margin: '16px'
  },
  print_anywhere_title: {
    fontSize: '40px',
    fontWeight: '400',
    color: '#212121',
    letterSpacing: 0,
    verticalAlign: 'middle',
    lineHeight: '49px'
  },

  print_anywhere_text_div: {
    width: '100%'
  },
  print_anywhere_text_one: {
    marginBottom: '25px',
    marginTop: '10px'
  },

  print_anywhere_text_two: {
    marginBottom: '35px'
  }

  // @media (min-width: 768px) {
  //     .printer_list__header {
  //         margin-bottom: 0;
  //     }

  //     .columns__print_anywhere {
  //         margin: 0 30px;
  //         text-align: center;
  //         flex: 0 1 25%;
  //     }

  //     .columns__private_pickup {
  //         margin: 0 10px;
  //         text-align: center;
  //         flex: 0 0 25%;
  //     }

  //     .columns__print_anywhere__title,
  //     .columns__private_pickup__title {
  //         font-weight: bold;
  //         font-size: 16px;
  //     }

  //     .columns__private_pickup__description {
  //         font-size: 12px;
  //     }
};

export default PrintAnyWhereTitleStyles;
