import { Button } from '@veneer/core';
import React from 'react';
import { useShellRootContext } from 'src/contexts/ShellRoot';

import styles from './SomethingWentWrongError.module.scss';

const SomethingWentWrongError = ({ onConfirm }) => {
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {};
  return (
    <div
      data-testid="potg-something-went-wrong-error"
      className={styles.something_went_wrong_error}
    >
      <h3 className={styles.something_went_wrong_error__description}>
        {t('somethingWentWrong.description', '')}
      </h3>
      <Button
        className={styles.something_went_wrong_error__button}
        onClick={onConfirm}
      >
        {t('somethingWentWrong.tryAgain', '')}
      </Button>
    </div>
  );
};

export default SomethingWentWrongError;
