import React from 'react';

export const buildQueryParamsString = (params) =>
  Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');

export const parseAndBuildString = (str, applyBold = true) => {
  let firstSplit = str && str.includes('<b>') ? str.split('<b>') : '';
  let beforeBold = firstSplit[0];
  let secondSplit =
    firstSplit && firstSplit.length > 0 ? firstSplit[1].split('</b>') : '';
  let tobeBold = secondSplit[0];
  let afterBold = secondSplit[1];
  return (
    <span>
      <span>{beforeBold}</span>
      <span>{applyBold ? <b>{tobeBold}</b> : tobeBold}</span>
      <span>{afterBold}</span>
    </span>
  );
};
