import React from 'react';

import styles from './styles';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { parseAndBuildString } from 'src/utils/string';

const PrintAnyWhereTitle = () => {
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {};
  return (
    <div
      data-testid="potg-print-anywhere-title-wrapper"
      style={styles.print_anywhere_intro}
    >
      <div>
        <span style={styles.print_anywhere_title}>{t('intro.title', '')}</span>
      </div>
      <div style={styles.print_anywhere_text_div}>
        <div>
          <p style={styles.print_anywhere_text_one}>
            {parseAndBuildString(t('staticPages.pageOne.paragOne', ''), true)}
          </p>
        </div>
        <div>
          <p style={styles.print_anywhere_text_two}>
            {parseAndBuildString(t('intro.privatePickup', ''), true)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrintAnyWhereTitle;
