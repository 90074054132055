const { useEffect, useState, useRef } = require('react');

function useBreakpoint(mediaQueries) {
  const [mediaQueryMatches, setMediaQueryMatches] = useState({});
  const eventHandlerAttached = useRef(false);

  useEffect(() => {
    const mediaQueryLists = {};
    const mediaKeys = Object.keys(mediaQueries);

    eventHandlerAttached.current = false;

    const handleMediaQueryListener = () => {
      const updatedMatches = mediaKeys.reduce((acc, media) => {
        acc[media] = acc[media] = !!(
          mediaQueryLists[media] && mediaQueryLists[media].matches
        );
        return acc;
      }, {});
      setMediaQueryMatches(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches = {};

      mediaKeys.forEach((media) => {
        if (typeof mediaQueries[media] === 'string') {
          mediaQueryLists[media] = window.matchMedia(mediaQueries[media]);
          matches[media] = mediaQueryLists[media].matches;
        } else {
          matches[media] = false;
        }
      });

      setMediaQueryMatches(matches);
      eventHandlerAttached.current = true;

      mediaKeys.forEach((media) => {
        if (typeof mediaQueries[media] === 'string') {
          mediaQueryLists[media].addListener(handleMediaQueryListener);
        }
      });
    }

    return () => {
      if (eventHandlerAttached.current) {
        mediaKeys.forEach((media) => {
          typeof mediaQueries[media] === 'string' &&
            mediaQueryLists[media].removeListener(handleMediaQueryListener);
        });
      }
    };
  }, [mediaQueries]);

  return mediaQueryMatches;
}

export default useBreakpoint;
