export const BREAKPOINT_MOBILE_SMALL = 'ms';
export const BREAKPOINT_MOBILE_MEDIUM = 'mm';
export const BREAKPOINT_MOBILE_LARGE = 'ml';
export const BREAKPOINT_SMALL = 'sm';
export const BREAKPOINT_TABLET = 'md';
export const BREAKPOINT_LAPTOP = 'lg';
export const BREAKPOINT_LAPTOP_LARGE = 'xl';

export default {
  [BREAKPOINT_MOBILE_SMALL]: '(min-width: 320px)',
  [BREAKPOINT_MOBILE_MEDIUM]: '(min-width: 375px)',
  [BREAKPOINT_MOBILE_LARGE]: '(min-width: 425px)',
  [BREAKPOINT_SMALL]: '(min-width: 570px)',
  [BREAKPOINT_TABLET]: '(min-width: 768px)',
  [BREAKPOINT_LAPTOP]: '(min-width: 1024px)',
  [BREAKPOINT_LAPTOP_LARGE]: '(min-width: 1440px)'
};
