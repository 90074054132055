import {
  Button,
  IconChevronDown,
  IconChevronUp,
  IconTrash
} from '@veneer/core';
import classnames from 'classnames';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { BREAKPOINT_TABLET } from '../../breakpoints';
import { StateContext } from '../../store';
import {
  actionConfirmationModalOpen,
  actionModalClose
} from '../../store/action-creators';
import IconBlack from '../icons/IconBlack';
import IconColor from '../icons/IconColor';
import {
  COLOR_MODE_AUTO,
  COLOR_MODE_AUTO_COLOR,
  COLOR_MODE_AUTO_MONOCHROME,
  COLOR_MODE_COLOR,
  COLOR_MODE_MONOCHROME
} from './constants';

import styles from './JobQueueTable.module.scss';

const JobRow = ({
  jobId,
  className,
  jobName,
  // imageUrl,
  status,
  pagesInJob,
  printColorMode,
  printerName,
  createdTime,
  releasePrinterId,
  handleDeleteJob,
  isHistoryTable,
  copiesCount
}) => {
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {};
  const {
    state: {
      breakpoints,
      printers: { printers }
    },
    dispatch
  } = useContext(StateContext);
  const [expanded, setExpanded] = useState(false);
  const [colorIcon, setColorIcon] = useState(false);
  const inMobileResolution = !breakpoints[BREAKPOINT_TABLET];
  useEffect(() => {
    const printer = isHistoryTable
      ? printers.find(({ cloudId }) => cloudId == releasePrinterId) || {}
      : printers.find(({ id }) => id == releasePrinterId) || {};
    if (
      printer.colorSupported === false ||
      printColorMode === COLOR_MODE_MONOCHROME ||
      printColorMode === COLOR_MODE_AUTO_MONOCHROME
    ) {
      setColorIcon(false);
    } else if (
      printColorMode === COLOR_MODE_AUTO ||
      printColorMode === COLOR_MODE_COLOR ||
      printColorMode === COLOR_MODE_AUTO_COLOR
    ) {
      setColorIcon(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getTableCellStyles = () => {
    if (isHistoryTable && status != 'success') {
      return styles.table__cell_center;
    } else {
      return styles.table__cell;
    }
  };
  const getExpandIcon = () => {
    if (expanded) {
      return <IconChevronUp />;
    } else {
      return <IconChevronDown />;
    }
  };
  const getTableCellValue = () => {
    if (!isHistoryTable) {
      return moment(createdTime).format('MM/DD/YYYY HH:mm');
    } else if (status !== 'success') {
      return '-';
    } else {
      return moment(createdTime).format('MM/DD/YYYY HH:mm');
    }
  };
  const tableTestId = isHistoryTable ? 'history' : 'queue';
  return (
    <>
      <tr
        data-testid={`potg-job-${tableTestId}-table-row`}
        className={classnames(
          styles.table__row,
          inMobileResolution ? styles.table__row_overview : '',
          className
        )}
      >
        <td
          data-testid={`potg-job-${tableTestId}-table-job`}
          className={styles.table__cell}
        >
          <div className={styles.cell__job_info}>
            <div className={styles.cell__job_short_info}>
              <h5
                data-testid={`potg-job-${tableTestId}-table-job-name`}
                className={styles.cell__job_name}
              >
                {jobName}
              </h5>

              <span
                data-testid="potg-job-queue-table-job-status"
                className={styles.cell__job_status}
              >
                {t(`jobsTable.status.${status.toLowerCase()}`, '')}
              </span>
            </div>
          </div>
        </td>
        {isHistoryTable && (
          <td
            className={classnames(styles.table__cell_pages, styles.table__cell)}
          >
            <span data-testid={`potg-job-${tableTestId}-table-job-pages`}>
              {copiesCount}
            </span>
          </td>
        )}
        <td
          className={classnames(styles.table__cell_pages, styles.table__cell)}
        >
          <span data-testid={`potg-job-${tableTestId}-table-job-pages`}>
            {pagesInJob}
          </span>
        </td>
        {!inMobileResolution ? (
          <>
            <td
              data-testid={`potg-job-${tableTestId}-table-job-color`}
              className={styles.table__cell}
            >
              {colorIcon ? <IconColor /> : <IconBlack />}
            </td>
            <td
              data-testid={`potg-job-${tableTestId}-table-job-printer-name`}
              className={styles.table__cell}
            >
              {printerName && printerName !== '' && <span>{printerName}</span>}
            </td>
            <td
              data-testid={`potg-job-${tableTestId}-table-job-created-time`}
              className={getTableCellStyles()}
            >
              {getTableCellValue()}
            </td>
            {!isHistoryTable && (
              <td className={styles.table__cell}>
                <Button
                  data-testid={`potg-job-${tableTestId}-table-job-delete-button`}
                  appearance="secondary"
                  leadingIcon={<IconTrash />}
                  style={{ borderWidth: 0 }}
                  onClick={() => {
                    dispatch(
                      actionConfirmationModalOpen({
                        message: t('privatePickupFiles.delete.confirm', ''),
                        onConfirm: () => {
                          dispatch(actionModalClose());
                          handleDeleteJob(jobId);
                        },
                        confirmLabel: t('delete', ''),
                        onCancel: () => dispatch(actionModalClose()),
                        cancelLabel: t('cancel', '')
                      })
                    );
                  }}
                />
              </td>
            )}
          </>
        ) : (
          <td className={styles.table__cell}>
            <div className={styles.cell_actions}>
              {!isHistoryTable && (
                <Button
                  data-testid={`potg-job-${tableTestId}-table-job-delete-button`}
                  appearance="secondary"
                  leadingIcon={<IconTrash />}
                  style={{ borderWidth: 0 }}
                  onClick={() => {
                    dispatch(
                      actionConfirmationModalOpen({
                        message: t('privatePickupFiles.delete.confirm', ''),
                        onConfirm: () => {
                          dispatch(actionModalClose());
                          handleDeleteJob(jobId);
                        },
                        confirmLabel: t('delete', ''),
                        onCancel: () => dispatch(actionModalClose()),
                        cancelLabel: t('cancel', '')
                      })
                    );
                  }}
                />
              )}
              <Button
                appearance="secondary"
                id="expanded-btn"
                data-testid="expanded-btn"
                leadingIcon={getExpandIcon}
                style={{ borderWidth: 0 }}
                onClick={() => setExpanded(!expanded)}
              />
            </div>
          </td>
        )}
      </tr>
      {inMobileResolution && (
        <tr
          data-testid={`potg-job-${tableTestId}-table-row-expandable`}
          className={classnames(
            styles.table__row,
            styles.table__row_details,
            expanded && styles.table__row_details_expanded,
            className
          )}
        >
          <td
            className={classnames(
              styles.table_cell,
              styles.table_cell_expandable
            )}
            colSpan={3}
          >
            <table
              data-testid={`potg-job-${tableTestId}-table-row-details-table`}
              className={styles.table_cell__details_table}
            >
              <tbody>
                <tr className={styles.details_table__row}>
                  <td
                    className={classnames(
                      styles.details_table__cell,
                      styles.details_table__cell_header
                    )}
                  >
                    {t('jobsTable.columns.color', '')}
                  </td>
                  <td
                    data-testid={`potg-job-${tableTestId}-table-job-color`}
                    className={styles.details_table__cell}
                  >
                    {colorIcon ? <IconColor /> : <IconBlack />}
                  </td>
                </tr>
                <tr className={styles.details_table__row}>
                  <td
                    className={classnames(
                      styles.details_table__cell,
                      styles.details_table__cell_header
                    )}
                  >
                    {t('jobsTable.columns.printer', '')}
                  </td>
                  <td
                    data-testid={`potg-job-${tableTestId}-table-job-printer-name`}
                    className={styles.details_table__cell}
                  >
                    {printerName && printerName !== '' && (
                      <span>{printerName}</span>
                    )}
                  </td>
                </tr>
                <tr className={styles.details_table__row}>
                  <td
                    className={classnames(
                      styles.details_table__cell,
                      styles.details_table__cell_header
                    )}
                  >
                    {isHistoryTable
                      ? t('jobsTable.columns.printTime', '')
                      : t('jobsTable.columns.submitTime', '')}
                  </td>
                  <td
                    data-testid={
                      isHistoryTable
                        ? 'potg-job-history-table-job-created-time'
                        : 'potg-job-queue-table-job-created-time'
                    }
                    className={styles.details_table__cell}
                  >
                    {status != 'success'
                      ? '-'
                      : moment(createdTime).format('MM/DD/YYYY HH:mm')}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
};

export default React.memo(JobRow);
