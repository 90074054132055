import React from 'react';
import { Container } from './styles';
import Potg from 'src/components/Potg';

const App = () => {
  return (
    <Container>
      <Potg />
    </Container>
  );
};

export default App;
