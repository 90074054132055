const getTimeFormatted = () => {
  const date = new Date();
  const hour = `${date.getHours()}`.padStart(2, '0');
  const minutes = `${date.getMinutes()}`.padStart(2, '0');
  const seconds = `${date.getSeconds()}`.padStart(2, '0');
  return `${hour}:${minutes}:${seconds}`;
};

export const getDerivedState = (state, action, reducers) =>
  Object.entries(reducers).reduce(
    (acc, [reducerName, reducerFunction]) =>
      typeof reducerFunction === 'function'
        ? { ...acc, [reducerName]: reducerFunction(acc[reducerName], action) }
        : acc,
    state
  );

export const actionLogger = (state, action, reducers) => {
  const timeString = getTimeFormatted();
  console.group(
    `%c action %c ${action.type} @ ${timeString}`,
    'color: #888',
    'color: #333; font-weight: bold'
  );
  console.groupCollapsed('%c prev state', 'color: #888');
  console.log(state);
  console.groupEnd();
  console.groupCollapsed('%c action', 'color: blue');
  console.log(action);
  console.groupEnd();
  console.groupCollapsed('%c next state', 'color: green');
  console.log(getDerivedState(state, action, reducers));
  console.groupEnd();
  console.groupEnd();
};

export const combineReducers = (reducers) => {
  const initialGlobalState = {};

  for (const [name, reducer] of Object.entries(reducers)) {
    if (typeof reducer === 'function') {
      initialGlobalState[name] = reducer(undefined, {
        type: '__DUMMY_ACTION__'
      });
    } else {
      console.error(`${reducer} is not a function!`);
    }
  }

  const reducerFunction = (state, action) => {
    let globalStateChanged = false;
    const globalNextState = {};

    actionLogger(state, action, reducers);

    for (const reducerName in reducers) {
      // https://eslint.org/docs/rules/no-prototype-builtins
      if (Object.hasOwnProperty.call(reducers, reducerName)) {
        const previousState = state[reducerName];
        const reducer = reducers[reducerName];

        const nextState = reducer(previousState, action);

        const stateChanged = nextState !== previousState;

        globalStateChanged = globalStateChanged || stateChanged;

        globalNextState[reducerName] = nextState;
      }
    }

    return globalStateChanged ? globalNextState : state;
  };

  return [initialGlobalState, reducerFunction];
};
