import {
  ACTION_FETCH_JOB_HISTORY_API,
  ACTION_FETCH_PRINTERS,
  ACTION_FETCH_PRINTERS_FAILURE,
  ACTION_FETCH_PRINTERS_SUCCESS,
  ACTION_UPDATE_PRINTER_DATA,
  ACTION_UPDATE_PRINTER_FEATURE_LOADING,
  ACTION_USER_UPDATE_NON_OWNER
} from './action-types';

const { ACTION_MODAL_OPEN, ACTION_MODAL_CLOSE } = require('./action-types');

const MODAL_INITIAL_STATE = {
  visible: false,
  component: null,
  props: null
};

function modalReducer(state = MODAL_INITIAL_STATE, action) {
  switch (action.type) {
    case ACTION_MODAL_OPEN:
      return {
        ...state,
        visible: true,
        component: action.payload.component,
        props: action.payload.props
      };
    case ACTION_MODAL_CLOSE:
      return { ...state, visible: false, component: null, props: null };
    default:
      return state;
  }
}

const USER_INITIAL_STATE = {
  nonOwner: false
};

function userReducer(state = USER_INITIAL_STATE, action) {
  if (action.type === ACTION_USER_UPDATE_NON_OWNER) {
    return {
      ...state,
      nonOwner: action.payload.nonOwner
    };
  } else {
    return state;
  }
}

const PRINTERS_INITIAL_STATE = {
  loading: false,
  printerSharing: false,
  printers: [],
  error: null,
  featuresLoading: {}
};

function printersReducer(state = PRINTERS_INITIAL_STATE, action) {
  switch (action.type) {
    case ACTION_FETCH_PRINTERS:
      return {
        ...state,
        loading: true,
        error: null
      };

    case ACTION_FETCH_PRINTERS_SUCCESS:
      return {
        ...state,
        printerSharing: action.payload.printerSharing,
        printers: action.payload.printers,
        loading: false,
        error: null,
        featuresLoading: action.payload.printers.reduce(
          (acc, printer) => ({
            ...acc,
            [printer.id]: {
              printOnTheGoEnabled: false,
              preferredReleaseIntent: false
            }
          }),
          {}
        )
      };
    case ACTION_FETCH_PRINTERS_FAILURE:
      return {
        ...state,
        printers: [],
        loading: false,
        error: action.payload.error,
        featuresLoading: {}
      };
    case ACTION_UPDATE_PRINTER_DATA:
      return {
        ...state,
        printers: state.printers.map((printer) =>
          printer.id === action.payload.printerId
            ? { ...printer, ...action.payload.data }
            : printer
        )
      };
    case ACTION_UPDATE_PRINTER_FEATURE_LOADING:
      return {
        ...state,
        featuresLoading: {
          ...state.featuresLoading,
          [action.payload.printerId]: {
            ...state.featuresLoading[action.payload.printerId],
            [action.payload.featureName]: action.payload.loading
          }
        }
      };
    default:
      return state;
  }
}

const FETCH_JOB_HISTORY_API_INITIAL_STATE = {
  shouldFetchJobHistoryApi: false
};
function jobHistoryFetchReducer(
  state = FETCH_JOB_HISTORY_API_INITIAL_STATE,
  action
) {
  if (action.type === ACTION_FETCH_JOB_HISTORY_API) {
    return {
      ...state,
      shouldFetchJobHistoryApi: action.payload.shouldFetchJobHistoryApi
    };
  } else {
    return state;
  }
}

export default {
  modal: modalReducer,
  printers: printersReducer,
  user: userReducer,
  jobHistoryFetch: jobHistoryFetchReducer
};
