import React from 'react';
import { Card } from '@veneer/core';
import PrinterList from './PrinterList';

import styles from './PrintersPanel.module.scss';

const PrintersPanel = () => (
  <div
    data-testid="potg-printers-panel-wrapper"
    className={styles.printers_panel_wrapper}
  >
    <Card
      className={styles.printers_panel}
      style={{ borderRadius: '12px' }}
      appearance="dropShadow"
      content={<PrinterList />}
    />
  </div>
);

export default PrintersPanel;
