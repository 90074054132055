// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BjHWochYxSzfwRy_p7qk{margin-bottom:8px;display:flex}.ulaKWu0i_uQzmUb9QfG9,.rcDdFIHKF918Eh0qCq_G{font-size:20px;font-weight:bold;color:#464d50;flex:0 0 50%}.rcDdFIHKF918Eh0qCq_G{margin-bottom:24px}.sBF0DAV2cFu_wKyTywRQ{font-size:16px;color:#464d50;margin-bottom:10px}@media(min-width: 768px){.BjHWochYxSzfwRy_p7qk{margin-bottom:0}.vHFVHR4uqRiarCbTDWg5{margin:0 30px;text-align:center;flex:0 1 25%}.Sp8k_dXFUcG7RP2S3wmS{margin:0 10px;text-align:center;flex:0 0 25%}.bzpMTF_6XP3ViS79JZcJ,.rNyxNvwrKPiXNFg3F9TJ{font-weight:bold;font-size:16px}.PiSF66oEO_urwdwg127i{font-size:12px}}", "",{"version":3,"sources":["webpack://./src/components/PrintersPanel/PrinterList.module.scss"],"names":[],"mappings":"AAAA,sBAAA,iBAAA,CAAA,YAAA,CAAA,4CAAA,cAAA,CAAA,gBAAA,CAAA,aAAA,CAAA,YAAA,CAAA,sBAAA,kBAAA,CAAA,sBAAA,cAAA,CAAA,aAAA,CAAA,kBAAA,CAAA,yBAAA,sBAAA,eAAA,CAAA,sBAAA,aAAA,CAAA,iBAAA,CAAA,YAAA,CAAA,sBAAA,aAAA,CAAA,iBAAA,CAAA,YAAA,CAAA,4CAAA,gBAAA,CAAA,cAAA,CAAA,sBAAA,cAAA,CAAA","sourcesContent":[".printer_list__header{margin-bottom:8px;display:flex}.printer_list__title,.printers_panel__no_printers__title{font-size:20px;font-weight:bold;color:#464d50;flex:0 0 50%}.printers_panel__no_printers__title{margin-bottom:24px}.printers_panel__no_printers__description{font-size:16px;color:#464d50;margin-bottom:10px}@media(min-width: 768px){.printer_list__header{margin-bottom:0}.columns__print_anywhere{margin:0 30px;text-align:center;flex:0 1 25%}.columns__private_pickup{margin:0 10px;text-align:center;flex:0 0 25%}.columns__print_anywhere__title,.columns__private_pickup__title{font-weight:bold;font-size:16px}.columns__private_pickup__description{font-size:12px}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"printer_list__header": "BjHWochYxSzfwRy_p7qk",
	"printer_list__title": "ulaKWu0i_uQzmUb9QfG9",
	"printers_panel__no_printers__title": "rcDdFIHKF918Eh0qCq_G",
	"printers_panel__no_printers__description": "sBF0DAV2cFu_wKyTywRQ",
	"columns__print_anywhere": "vHFVHR4uqRiarCbTDWg5",
	"columns__private_pickup": "Sp8k_dXFUcG7RP2S3wmS",
	"columns__print_anywhere__title": "bzpMTF_6XP3ViS79JZcJ",
	"columns__private_pickup__title": "rNyxNvwrKPiXNFg3F9TJ",
	"columns__private_pickup__description": "PiSF66oEO_urwdwg127i"
};
export default ___CSS_LOADER_EXPORT___;
