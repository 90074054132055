import React from 'react';

const IconBlack = () => (
  <svg
    data-testid="potg-icon-black"
    xmlns="http://www.w3.org/2000/svg"
    width="17.684"
    height="17.684"
    viewBox="0 0 17.684 17.684"
  >
    <circle
      cx="8.842"
      cy="8.842"
      r="8.842"
    />
  </svg>
);

export default IconBlack;
