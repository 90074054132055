// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mWmV5ujD1Vi5y8eccN3K{max-width:956px;margin-bottom:16px}.zr9gdb3XdFahqB19tmAE{padding:15px 16px;border-radius:12px}@media(min-width: 768px){.zr9gdb3XdFahqB19tmAE{padding:24px 32px}}", "",{"version":3,"sources":["webpack://./src/components/PrintersPanel/PrintersPanel.module.scss"],"names":[],"mappings":"AAAA,sBAAA,eAAA,CAAA,kBAAA,CAAA,sBAAA,iBAAA,CAAA,kBAAA,CAAA,yBAAA,sBAAA,iBAAA,CAAA","sourcesContent":[".printers_panel_wrapper{max-width:956px;margin-bottom:16px}.printers_panel{padding:15px 16px;border-radius:12px}@media(min-width: 768px){.printers_panel{padding:24px 32px}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"printers_panel_wrapper": "mWmV5ujD1Vi5y8eccN3K",
	"printers_panel": "zr9gdb3XdFahqB19tmAE"
};
export default ___CSS_LOADER_EXPORT___;
