import { useApiCall } from '@jarvis/react-portal-addons';
import { JarvisWebHttpClient } from '@jarvis/web-http';
import { useCallback } from 'react';
import { getPrintAnywhereUrl } from 'src/services/api';

export default function useRolesApiCall(userId) {
  const apiCall = useCallback(async () => {
    const client = new JarvisWebHttpClient({
      defaultBaseURL: getPrintAnywhereUrl(window.Shell.v1.app.getAuthStack()),
      defaultAuthProvider: window.Shell.v1.authProvider
    });
    const options = {
      url: `/api/v1/role?userId=${userId}`
    };
    if (userId) {
      try {
        const response = await client.get(options);
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  }, [userId]);

  return useApiCall({ apiCall });
}
