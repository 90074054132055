export const PAPER_SIZES = [
  { value: 'na_letter_8.5x11in', label: 'US Letter' },
  { value: 'na_ledger_11x17in', label: 'US Tabloid' },
  { value: 'na_legal_8.5x14in', label: 'US Legal' },
  { value: 'na_invoice_5.5x8.5in', label: 'US Statement' },
  { value: 'na_executive_7.25x10.5in', label: 'US Executive' },
  { value: 'iso_a3_297x420mm', label: 'A3' },
  { value: 'iso_a4_210x297mm', label: 'A4' },
  { value: 'iso_a4_210x297mm', label: 'A4 Small' },
  { value: 'iso_a5_148x210mm', label: 'A5' },
  { value: 'jis_b4_257x364mm', label: 'B4 (JIS)' },
  { value: 'jis_b5_182x257mm', label: 'B5 (JIS)' },
  { value: 'om_folio_210x330mm', label: 'Folio' },
  { value: 'na_quarto_8.5x10.83in', label: 'Quarto' },
  { value: 'na_10x14_10x14in', label: '10x14 (envelope)' },
  { value: 'na_ledger_11x17in', label: '11x17 (envelope)' },
  { value: 'na_letter_8.5x11in', label: 'US Note' },
  { value: 'na_number-9_3.875x8.875in', label: 'US Envelope #9' },
  { value: 'na_number-10_4.125x9.5in', label: 'US Envelope #10' },
  { value: 'na_number-11_4.5x10.375in', label: 'US Envelope #11' },
  { value: 'na_number-12_4.75x11in', label: 'US Envelope #12' },
  { value: 'na_number-14_5x11.5in', label: 'US Envelope #14' },
  { value: 'na_c_17x22in', label: 'C size sheet' },
  { value: 'na_d_22x34in', label: 'D size sheet' },
  { value: 'na_e_34x44in', label: 'E size sheet' },
  { value: 'iso_dl_110x220mm', label: 'Envelope DL' },
  { value: 'iso_c5_162x229mm', label: 'Envelope C5' },
  { value: 'iso_c3_324x458mm', label: 'Envelope C3' },
  { value: 'iso_c4_229x324mm', label: 'Envelope C4' },
  { value: 'iso_c6_114x162mm', label: 'Envelope C6' },
  { value: 'iso_c6c5_114x229mm', label: 'Envelope C65' },
  { value: 'iso_b4_250x353mm', label: 'Envelope B4' },
  { value: 'iso_b5_176x250mm', label: 'Envelope B5' },
  { value: 'iso_b6_125x176mm', label: 'Envelope B6' },
  { value: 'om_italian_110x230mm', label: 'Italian Envelope' },
  { value: 'na_monarch_3.875x7.5in', label: 'US Envelope Monarch' },
  { value: 'na_personal_3.625x6.5in', label: 'US Personal Envelope' },
  { value: 'na_fanfold-us_11x14.875in', label: 'US Std Fanfold' },
  { value: 'na_fanfold-eur_8.5x12in', label: 'German Std Fanfold' },
  { value: 'na_foolscap_8.5x13in', label: 'German Legal Fanfold' },
  { value: 'jpn_hagaki_100x148mm', label: 'Japanese Postcard' }
];

export const COLOR_MODE_MONOCHROME = 'monochrome';
export const COLOR_MODE_COLOR = 'color';
export const COLOR_MODE_AUTO = 'auto';
export const COLOR_MODE_AUTO_MONOCHROME = 'auto-monochrome';
export const COLOR_MODE_AUTO_COLOR = 'auto-color';

export const COLOR_MODES = [
  { value: COLOR_MODE_MONOCHROME, label: 'Black & White' },
  { value: COLOR_MODE_COLOR, label: 'Color' }
];

export const PRINTING_SIDES = [
  { value: 'one-sided', label: 'One Sided' },
  { value: 'two-sided-long-edge', label: 'Two Sided Long Edge' },
  { value: 'two-sided-short-edge', label: 'Two Sided Short Edge' }
];

export const ALLOWED_FILE_TYPES = [
  { extension: '.doc', mimeType: 'application/msword' },
  { extension: '.pdf', mimeType: 'application/pdf' },
  { extension: '.xls', mimeType: 'application/vnd.ms-excel' },
  { extension: '.ppt', mimeType: 'application/vnd.ms-powerpoint' },
  {
    extension: '.pptx',
    mimeType:
      'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  },
  {
    extension: '.xlsx',
    mimeType:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  },
  {
    extension: '.docx',
    mimeType:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  },
  { extension: '.png', mimeType: 'image/png' },
  { extension: '.bmp', mimeType: 'image/bmp' },
  { extension: '.gif', mimeType: 'image/gif' },
  { extension: '.jpg', mimeType: 'image/jpeg' },
  { extension: '.jpeg', mimeType: 'image/jpeg' },
  { extension: '.pjpeg', mimeType: 'image/pjpeg' },
  { extension: '.tiff', mimeType: 'image/tiff' },
  { extension: '.tif', mimeType: 'image/tiff' },
  { extension: '.html', mimeType: 'text/html' },
  { extension: '.htm', mimeType: 'text/html' },
  { extension: '.txt', mimeType: 'text/plain' },
  { extension: '.asc', mimeType: 'text/plain' },
  { extension: '.rtf', mimeType: 'text/rtf' }
];

export const JOB_STATUS_READY = 'READY';
export const JOB_STATUS_PREPARING = 'PREPARING';
