import React from 'react';
import {
  ACTION_MODAL_OPEN,
  ACTION_MODAL_CLOSE,
  ACTION_FETCH_PRINTERS_SUCCESS,
  ACTION_FETCH_PRINTERS,
  ACTION_FETCH_PRINTERS_FAILURE,
  ACTION_UPDATE_PRINTER_DATA,
  ACTION_UPDATE_PRINTER_FEATURE_LOADING,
  ACTION_USER_UPDATE_NON_OWNER,
  ACTION_UPDATE_PRINTER_SHARING,
  ACTION_FETCH_JOB_HISTORY_API
} from './action-types';
import { ConfirmationModal } from '@veneer/core';

export const actionConfirmationModalOpen = ({
  title = '',
  message,
  onConfirm,
  onCancel,
  confirmLabel,
  cancelLabel,
  align = 'center'
}) => ({
  type: ACTION_MODAL_OPEN,
  payload: {
    component: ConfirmationModal,
    props: {
      title,
      children: <p>{message}</p>,
      onConfirm: onConfirm,
      confirmButtonLabel: confirmLabel,
      onCancel: onCancel,
      closeButtonLabel: cancelLabel,
      align
    }
  }
});

export const actionModalClose = () => ({
  type: ACTION_MODAL_CLOSE
});

export const actionFetchPrinters = () => ({
  type: ACTION_FETCH_PRINTERS
});

export const actionUserUpdateNonOwner = (nonOwner) => ({
  type: ACTION_USER_UPDATE_NON_OWNER,
  payload: {
    nonOwner
  }
});

export const actionUpdatePrinterSharing = (printerSharing) => ({
  type: ACTION_UPDATE_PRINTER_SHARING,
  payload: {
    printerSharing
  }
});

export const actionFetchPrintersSuccess = (printers, printerSharing) => ({
  type: ACTION_FETCH_PRINTERS_SUCCESS,
  payload: {
    printers,
    printerSharing
  }
});

export const actionFetchPrintersFailure = (error) => ({
  type: ACTION_FETCH_PRINTERS_FAILURE,
  payload: {
    error
  }
});

export const actionUpdatePrinterData = ({ printerId, data }) => ({
  type: ACTION_UPDATE_PRINTER_DATA,
  payload: {
    printerId,
    data
  }
});

export const actionUpdatePrinterFeatureLoading = ({
  printerId,
  featureName,
  loading
}) => ({
  type: ACTION_UPDATE_PRINTER_FEATURE_LOADING,
  payload: {
    printerId,
    featureName,
    loading
  }
});

export const actionFetchJobHistoryApi = (shouldFetchJobHistoryApi) => ({
  type: ACTION_FETCH_JOB_HISTORY_API,
  payload: {
    shouldFetchJobHistoryApi
  }
});
