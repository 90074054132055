import React from 'react';

const IconPositiveArrow = () => (
  <svg
    data-testid="potg-icon-positive-arrow"
    width="76"
    height="76"
    viewBox="0 0 76 76"
  >
    <path
      style={{ fill: '#1c7a17' }}
      d="M33.667,5.167a28.5,28.5,0,1,1-28.5,28.5,28.532,28.532,0,0,1,28.5-28.5m0-3.167A31.667,31.667,0,1,0,65.333,33.667,31.667,31.667,0,0,0,33.667,2ZM28.583,44.84,49,24.751a1.583,1.583,0,1,0-2.22-2.257l-19.309,19-8.554-8.415a1.583,1.583,0,1,0-2.22,2.257l9.664,9.506a1.583,1.583,0,0,0,2.22,0Z"
      transform="translate(4.342 4.332)"
    />
    <rect
      style={{ fill: 'none' }}
      width="76"
      height="76"
    />
  </svg>
);

export default IconPositiveArrow;
