import bg_BG from './bg_BG.json';
import cs_CZ from './cs_CZ.json';
import da_DK from './da_DK.json';
import de_DE from './de_DE.json';
import el_GR from './el_GR.json';
import en_CA from './en_CA.json';
import en_GB from './en_GB.json';
import en_US from './en_US.json';
import es_ES from './es_ES.json';
import fi_FI from './fi_FI.json';
import fr_CA from './fr_CA.json';
import fr_FR from './fr_FR.json';
import hr_HR from './hr_HR.json';
import hu_HU from './hu_HU.json';
import it_IT from './it_IT.json';
import ja_JP from './ja_JP.json';
import ko_KR from './ko_KR.json';
import lt_LT from './lt_LT.json';
import lv_LV from './lv_LV.json';
import nb_NO from './nb_NO.json';
import nl_NL from './nl_NL.json';
import pl_PL from './pl_PL.json';
import pt_AO from './pt_AO.json';
import pt_BR from './pt_BR.json';
import pt_MZ from './pt_MZ.json';
import pt_PT from './pt_PT.json';
import ro_MD from './ro_MD.json';
import ro_RO from './ro_RO.json';
import ru_RU from './ru_RU.json';
import sk_SK from './sk_SK.json';
import sl_SI from './sl_SI.json';
import sv_SE from './sv_SE.json';
import tr_TR from './tr_TR.json';
import zh_CN from './zh_CN.json';
import zh_HK from './zh_HK.json';
import zh_TW from './zh_TW.json';
import zh_ZH from './zh_ZH.json';

export default {
  bg_BG: bg_BG,
  cs_CZ: cs_CZ,
  da_DK: da_DK,
  de_DE,
  de_LI: de_DE,
  el_GR: el_GR,
  en_AD: en_US,
  en_CA: en_CA,
  en_GB: en_GB,
  en_IS: en_US,
  en_US,
  es_ES,
  et_EE: en_US,
  fi_FI: fi_FI,
  fr_CA,
  fr_FR,
  hr_HR: hr_HR,
  hu_HU: hu_HU,
  it_IT,
  ja_JP: ja_JP,
  ko_KR: ko_KR,
  lt_LT: lt_LT,
  lv_LV: lv_LV,
  nb_NO: nb_NO,
  nl_NL: nl_NL,
  pl_PL: pl_PL,
  pt_AO,
  pt_BR,
  pt_MZ,
  pt_PT: pt_PT,
  ro_MD: ro_MD,
  ro_RO: ro_RO,
  ru_RU: ru_RU,
  sk_SK: sk_SK,
  sl_SI: sl_SI,
  sv_SE: sv_SE,
  tr_TR: tr_TR,
  zh_CN,
  zh_HK: zh_HK,
  zh_TW: zh_TW,
  zh_ZH
};
