import { useApiCall } from '@jarvis/react-portal-addons';
import { JarvisWebHttpClient } from '@jarvis/web-http';
import moment from 'moment';
import { useCallback } from 'react';
import { getPrintAnywhereUrl } from 'src/services/api';

export default function useJobHistoryApiCall(
  stratusTenantId,
  stratusResourceId,
  nonOwner
) {
  const apiCall = useCallback(async () => {
    const client = new JarvisWebHttpClient({
      defaultBaseURL: getPrintAnywhereUrl(window.Shell.v1.app.getAuthStack()),
      defaultAuthProvider: window.Shell.v1.authProvider
    });

    const now = moment.utc();
    const nowParam = now.format('YYYY-MM-DD');
    const thirtyDayAgo = now.subtract(30, 'days');
    const thirtyDayAgoParam = thirtyDayAgo.format('YYYY-MM-DD');
    const stratusParams = nonOwner
      ? `&userId=${stratusResourceId}`
      : `&userId=${stratusResourceId}&accountId=${stratusTenantId}`;

    const options = {
      url: `/api/v1/telemetry/v1/jobs/print?startDate=${thirtyDayAgoParam}&endDate=${nowParam}${stratusParams}`
    };
    try {
      const response = await client.get(options);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }, [stratusTenantId, stratusResourceId, nonOwner]);

  return useApiCall({ apiCall });
}
