const PotgStyles = {
  potg_app: {
    margin: '0 32px',
    backgroundColor: 'transparent'
  },
  potg_printer_panel__spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '120px'
  }
};
export default PotgStyles;
