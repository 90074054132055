import React, { useContext } from 'react';
import { createPortal } from 'react-dom';
import { StateContext } from '../../store';
import { ACTION_MODAL_CLOSE } from '../../store/action-types';
import usePortal from '../../hooks/usePortal';

const PotgModal = () => {
  const {
    state: {
      modal: { visible, component: ModalComponent, props }
    },
    dispatch
  } = useContext(StateContext);
  const target = usePortal('modal-container');

  if (!visible) return null;

  return createPortal(
    <ModalComponent
      id="potg-modal"
      data-testid="potg-modal"
      show={true}
      {...props}
      onClose={() => dispatch({ type: ACTION_MODAL_CLOSE })}
    />,
    target
  );
};

export default PotgModal;
